import React from 'react';
import { env } from 'src/env';
import { CognitoAuthProvider } from '../clients/cognito/CognitoAuthProvider';
import { FirebaseAuthProvider } from '../clients/firebase/FirebaseAuthProvider';

interface IAuthWrapperProvider {
    children: React.ReactNode;
}

export const AuthProviderWrapper: React.FC<IAuthWrapperProvider> = ({ children }) => {
    const cloudEnv = env.VITE_CLOUD_ENV;

    if (cloudEnv === 'aws') {
        return (
            <CognitoAuthProvider>
                {children}
            </CognitoAuthProvider>
        );
    }
    if (cloudEnv === 'gcp') {
        return (
            <FirebaseAuthProvider>
                {children}
            </FirebaseAuthProvider>
        );
    }


};

export default AuthProviderWrapper;