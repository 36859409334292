import { createRoot } from 'react-dom/client';
import { ZodFormattedError, z } from 'zod';
import InvalidEnv from './pages/Fallbacks/Status/InvalidEnv/InvalidEnv';
import { formatErrors } from './utils/formatZodSafeParse';

const addIssue = (refinementContext: z.RefinementCtx, message: string, path: string) => {
    return refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: message,
        path: [path],
    });
}

export const clientSchema = z.object({
    // App
    VITE_BASE_API_URL: z.string().min(1),
    VITE_APP_BASE_URL: z.string().min(1),
    VITE_APP_NAME: z.string().min(1),
    VITE_DEPLOYMENT_ENV: z.enum(['development', 'staging', 'production']),
    VITE_REDIRECT_SUCCESS: z.string().min(1),
    VITE_REDIRECT_FAILURE: z.string().min(1),
    VITE_REDIRECT_CANCEL: z.string().min(1),
    VITE_CLOUD_ENV: z.enum(['gcp', 'aws']),

    // Sentry
    VITE_SENTRY_DSN: z.string().min(1),
    VITE_SENTRY_AUTH_TOKEN: z.string().min(1),

    // Cognito
    VITE_APP_COGNITO_USERPOOL_ID: z.string().optional(),
    VITE_APP_COGNITO_CLIENT_ID: z.string().optional(),

    // Firebase
    VITE_FIREBASE_API_KEY: z.string().optional(),
    VITE_FIREBASE_AUTH_DOMAIN: z.string().optional(),
    VITE_FIREBASE_PROJECT_ID: z.string().optional(),

    // Build
    VITE_BUILD_ID: z.string().min(1),
    VITE_BUILD_NUMBER: z.string().min(1),
    VITE_RELEASE_NAME: z.string().min(1),
    VITE_APP_VERSION: z.string().min(1),
})
    // Perform conditional validation to ensure conditional elements.
    .superRefine((data, refinementContext) => {
        if ((data.VITE_CLOUD_ENV === 'aws' && !data.VITE_APP_COGNITO_CLIENT_ID)) {
            addIssue(refinementContext, 'VITE_APP_COGNITO_CLIENT_ID is required when cloud env is aws', 'VITE_APP_COGNITO_CLIENT_ID');
        }
        if ((data.VITE_CLOUD_ENV === 'aws' && !data.VITE_APP_COGNITO_USERPOOL_ID)) {
            addIssue(refinementContext, 'VITE_APP_COGNITO_USERPOOL_ID is required when cloud env is aws', 'VITE_APP_COGNITO_USERPOOL_ID');
        }
        if ((data.VITE_CLOUD_ENV === 'gcp' && !data.VITE_FIREBASE_API_KEY)) {
            addIssue(refinementContext, 'VITE_FIREBASE_API_KEY is required when cloud env is gcp', 'VITE_FIREBASE_API_KEY');
        }
        if ((data.VITE_CLOUD_ENV === 'gcp' && !data.VITE_FIREBASE_AUTH_DOMAIN)) {
            addIssue(refinementContext, 'VITE_FIREBASE_AUTH_DOMAIN is required when cloud env is aws', 'VITE_FIREBASE_AUTH_DOMAIN');
        }
        if ((data.VITE_CLOUD_ENV === 'gcp' && !data.VITE_FIREBASE_PROJECT_ID)) {
            addIssue(refinementContext, 'VITE_FIREBASE_PROJECT_ID is required when cloud env is aws', 'VITE_FIREBASE_PROJECT_ID');
        }
    });

const _clientEnv = clientSchema.safeParse(import.meta.env);

if (!_clientEnv.success) {
    console.error(
        '❌ Invalid environment variables:\n',
        ...formatErrors(_clientEnv.error.format()),
    );

    // Check if the root element exists before rendering the ErrorComponent
    const rootElement = document.getElementById('root');
    if (rootElement) {
        createRoot(rootElement).render(<InvalidEnv message={formatErrors(_clientEnv.error.format())} />);
    } else {
        console.error('❌ Root element with id "root" not found.');
    }

    throw new Error('Invalid environment variables');
}

export const env = _clientEnv.data;
export const envSchema = clientSchema;