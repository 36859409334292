import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { _getCognitoPool } from "./_getCognitoPool";
import { _getCognitoUser } from "./_getCognitoUser";

// const COGNITO_USER_POOL_ID = env.COGNITO_USER_POOL_ID;
// const COGNITO_CLIENT_ID = env.COGNITO_CLIENT_ID;

function asyncPasswordForgotConfirm(
  cognitoUser: CognitoUser,
  verificationCode: string,
  newPassword: string
) {
  return new Promise(function (resolve, reject) {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        resolve("Password confirmed!");
        console.log("Password confirmed!");
      },
      onFailure(err) {
        reject(err);
        console.log("Password not confirmed!");
      },
    });
  });
}

interface ForgotPassDataProps {
  email: string;
  verificationCode: string;
  newPassword: string;
}

const cognitoForgotPasswordConfirmStart = async (
  registerData: ForgotPassDataProps
) => {
  const { email, verificationCode, newPassword } = registerData;

  const cognitoUser = _getCognitoUser(email)

  if (cognitoUser !== null) {
    try {
      const passwordForgotRes = await asyncPasswordForgotConfirm(
        cognitoUser,
        verificationCode,
        newPassword
      );
      console.log("success");
      console.log(passwordForgotRes);
      return passwordForgotRes;
    } catch (error: any) {
      console.log("error");
      console.log(error);
      return {
        error: true,
        message: error.message,
      };
    }
  }
};

export { cognitoForgotPasswordConfirmStart };
