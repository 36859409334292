import { Box, Typography, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { env } from 'src/env';
import { useUserAuthStore } from 'src/store/userAuth/userAuthStore';
import { getFriendlyUserType } from 'src/utils/getFriendlyUserType';

interface ILogoInterface {
  height?: number | string;
  width?: number | string;
}

function Logo(props: ILogoInterface) {
  const { height = 'auto', width = 'auto' } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const role = useUserAuthStore((state) => state.role);
  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname.includes('/admin/')) {
      navigate('/admin/jobs')
    }
    if (location.pathname.includes('/worker/')) {
      navigate('/worker/home')
    }
  }

  return (
    <>
      <Box sx={{ cursor: 'pointer' }}>
        <img src={theme.palette.mode === 'dark' ? '/static/images/brand/LogoAdmin.png' : '/static/images/brand/LogoAdmin.png'} alt='Logo' height={height} width={width} onClick={handleLogoClick} />
      </Box>
      <Typography variant='body2' color={'primary.dark'} fontSize={10}>
        {getFriendlyUserType(role) === 'TBD' ? '' : getFriendlyUserType(role)}
        {env.VITE_APP_BASE_URL.includes('localhost') ? ` - (${env.VITE_CLOUD_ENV})` : ''}
      </Typography>

    </>
  );
}

export default Logo;
