import { env } from 'src/env';

const firebaseConfig: Record<string, any> = {
    config: {
        apiKey: env.VITE_FIREBASE_API_KEY,
        authDomain: env.VITE_FIREBASE_AUTH_DOMAIN,
        projectId: env.VITE_FIREBASE_PROJECT_ID,
    }
};

export default firebaseConfig;